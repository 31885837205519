import useStyles from "./styles";
import React from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {INewsCard} from "./types";

const InfoCard = ({
                      card,
                  }: INewsCard) => {
    const styles = useStyles()

    return (
        <a href={`/views?id=${card.id}`} style={{ textDecoration: 'none' }}>
            <Card className={styles.root}>
                <CardContent>
                    <Typography variant="body2">
                        {card.title}
                    </Typography>
                    <Typography variant="caption">
                        {card.description}
                        {card.isValid && <span style={{color: "#037408"}}>Գործում է</span>}
                    </Typography>
                </CardContent>
            </Card>
        </a>
    );
}

export default InfoCard;
