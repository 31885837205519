import useStyles from "./styles";
import { ILayout } from "./types";
import React from "react";

const Layout = ({
    header,
    content,
    footer,
 }: ILayout) => {
    const styles = useStyles()
    return (
        <div className={styles.root}>
                {header && (
                  <div className={styles.headerContainer}>
                      {header}
                  </div>
                )}
                <div className={styles.contentContainer}>
                    {content}
                </div>
            {footer && (
                <div className={styles.footerContainer}>
                    {footer}
                </div>
            )}
        </div>
    )
};

export default Layout;
