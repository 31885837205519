import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "grid",
        gridTemplateRows: 'auto 1fr',
        gridTemplateColumns: '1fr',
        gap: 16,
        height: "100%",
        padding: "0 74px",
    },
    searchContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '120px',
    },
    markDownContainer: {
        padding: "24px 20px",
        backgroundColor: "#D9D9D9",
        borderRadius: 10,
    },
    markDown: {
        padding: 16,
        backgroundColor: "#FFFFFF",
    },
}, {
    name: 'home',
});

export default useStyles;
