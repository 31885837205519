import React, {useEffect, useState} from "react";
import ContnetLayout from "../../../components/layout/contentLayout/ContnetLayout";
import useStyles from "./styles";
import SearchBar from "../../../components/searchBar/SearchBar";
import ReactMarkdown from 'react-markdown'
import CardGroup from "../../../components/cardGroup/CardGroup";
import {ICard, IContext} from "../../../globalTypes/globalTypes";
import {getCardBy} from "../../../Services/HttpRequest";
import {useLocation} from "react-router-dom";
import {cards} from "../../mainPages/home/Home";

const Views = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    useEffect(() => {
        const getCard = async () => {
            try {
                const fetchedCard = await getCardBy(id ?? "");
                if ((fetchedCard as ICard).id) {
                    setLawCard(fetchedCard as ICard);
                } else {
                    setLawCard(cards[0]);
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        getCard();
    }, []);

    const styles = useStyles()

    const [lawCard, setLawCard] = useState<ICard>();

    return (
        <div className={styles.root}>
            <div className={styles.searchContainer}>
                <SearchBar title={"Որոնել"} onSerach={text => console.log(text)}/>
            </div>
            <ContnetLayout
                left={
                    <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                        {lawCard?.incoming?.map((context: IContext) => (<CardGroup context={context}/>))}
                    </div>
                }
                center={
                <div className={styles.markDownContainer}>
                    <div className={styles.markDown}>
                        {lawCard && <ReactMarkdown>{lawCard.content}</ReactMarkdown>}
                    </div>
                </div>
                }
                right={
                    <div style={{display: "flex", flexDirection: "column", gap: 8}}>
                        {lawCard?.outgoing?.map((context: IContext) => (<CardGroup context={context}/>))}
                    </div>
                }
            />
        </div>
    )
}

export default Views;
