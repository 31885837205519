import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
    },
    searchContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '200px',
        backgroundColor: "#EBEBEB",
    },
    newsCardListContainer: {
        padding: 20,
    }
}, {
    name: 'home',
});

export default useStyles;
