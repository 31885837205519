import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "grid",
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'minmax(160px, 342px) minmax(500px, auto) minmax(140px, 342px)',
        gap: 16,
        height: "100%",
        width: "100%"
    },
    leftContainer: { },
    centerContainer: {
        maxWidth: "100%",
    },
    rightContainer: { },
}, {
    name: 'content-layout',
});

export default useStyles;
