import React, {useState} from "react";
import InfoCard from "../../../components/infoCard/InfoCard";
import useStyles from "./styles";
import SearchBar from "../../../components/searchBar/SearchBar";
import {ICard} from "../../../globalTypes/globalTypes";
import {getCardsBy} from "../../../Services/HttpRequest";

export const content = 'Հոդված 1.\tՔաղաքացիական օրենսդրությամբ և քաղաքացիական իրավունքի նորմեր պարունակող այլ իրավական ակտերով կարգավորվող հարաբերությունները\n' +
    ' \n' +
    '\n' +
    '1. Հայաստանի Հանրապետության քաղաքացիական օրենսդրությունը կազմված է սույն օրենսգրքից և քաղաքացիական իրավունքի նորմեր պարունակող այլ օրենքներից:\n' +
    '\n' +
    'Այլ օրենքներում պարունակվող քաղաքացիական իրավունքի նորմերը պետք է համապատասխանեն սույն օրենսգրքին:\n' +
    '\n' +
    '2. Քաղաքացիական օրենսդրությունը, ինչպես նաև քաղաքացիական իրավունքի նորմեր պարունակող` Հայաստանի Հանրապետության Նախագահի հրամանագրերը և Հայաստանի Հանրապետության կառավարության որոշումները (այսուհետ` այլ իրավական ակտեր) սահմանում են քաղաքացիական շրջանառության մասնակիցների իրավական վիճակը, սեփականության իրավունքի և այլ գույքային իրավունքների, մտավոր գործունեության արդյունքների նկատմամբ բացառիկ իրավունքների (մտավոր սեփականություն) ծագման հիմքերն ու իրականացման կարգը, կարգավորում են պայմանագրային և այլ պարտավորություններ, ինչպես նաև այլ գույքային ու դրանց հետ կապված անձնական ոչ գույքային հարաբերություններ:\n' +
    '\n' +
    'Քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով կարգավորվող հարաբերությունների մասնակիցները` ֆիզիկական անձինք` Հայաստանի Հանրապետության քաղաքացիները, օտարերկրյա պետությունների քաղաքացիները, քաղաքացիություն չունեցող անձինք (այսուհետ` քաղաքացիներ) ու իրավաբանական անձինք են, ինչպես նաև Հայաստանի Հանրապետությունը և համայնքներն են (հոդված 128):\n' +
    '\n' +
    'Քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով սահմանված կանոնները կիրառվում են օտարերկրյա իրավաբանական անձանց մասնակցությամբ հարաբերությունների նկատմամբ, եթե այլ բան նախատեսված չէ օրենքով:\n' +
    '\n' +
    '3. Քաղաքացիական օրենսդրությունը և այլ իրավական ակտերը կարգավորում են ձեռնարկատիրական գործունեություն իրականացնող անձանց միջև կամ նրանց մասնակցությամբ հարաբերությունները:\n' +
    '\n' +
    '4. Ընտանեկան, աշխատանքային, բնական պաշարների օգտագործման ու շրջակա միջավայրի պահպանության հարաբերությունները կարգավորվում են քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով, եթե այլ բան նախատեսված չէ ընտանեկան, աշխատանքային, հողային, բնապահպան և այլ հատուկ օրենսդրությամբ:\n' +
    '\n' +
    '5. Մարդու անօտարելի իրավունքների ու ազատությունների և մյուս ոչ նյութական բարիքների իրականացման ու պաշտպանության հետ կապված հարաբերությունները կարգավորվում են քաղաքացիական օրենսդրությամբ և այլ իրավական ակտերով, եթե այլ բան չի բխում այդ հարաբերությունների էությունից:\n' +
    '\n' +
    '6. Մեկ կողմի` մյուսի վարչական կամ այլ իշխանական ենթակայության վրա հիմնված գույքային` ներառյալ հարկային, ֆինանսական ու վարչական հարաբերությունների նկատմամբ քաղաքացիական օրենսդրությունը և այլ իրավական ակտերը չեն կիրառվում, եթե այլ բան նախատեսված չէ օրենսդրությամբ:'


export const cards: ICard[] = [
        {
            id: "qax_or-chorrord_bazhin-glokh10-hodvats_217.2",
            title: "ՎԱՐՉԱԿԱՆ ԻՐԱՎԱԽԱԽՏՈՒՄՆԵՐԻ ՎԵՐԱԲԵԲՅԱԼ ՀՀ ՕՐԵՆՍԳԻՐՔ",
            description: "Օրենսգիրք Ընդունված է։ 06.12.1985  Ուժի մեջ է։  01.06.1986",
            isValid: true,
            content: content,
            incoming: [
                {
                    contextId: "1",
                    contextName: "Քարտային տվյալներ",
                    count: 0,
                },
                {
                    contextId: "2",
                    contextName: "Նախագծի մասին",
                    count: 0,
                },
                {
                    contextId: "3",
                    contextName: "ժամ.տարբերակներ",
                    count: 0,
                },
                {
                    contextId: "4",
                    contextName: "դատական ակտեր",
                    count: 0,
                }],
            outgoing: [
                {
                    contextId: "1",
                    contextName: "Քարտային տվյալներ",
                    count: 0,
                },
                {
                    contextId: "2",
                    contextName: "Նախագծի մասին",
                    count: 0,
                },
                {
                    contextId: "3",
                    contextName: "ժամ.տարբերակներ",
                    count: 0,
                },
                {
                    contextId: "4",
                    contextName: "դատական ակտեր",
                    count: 0,
                }],
        },
];
const Home = () => {
    const styles = useStyles();

    const [lawCards, setLawCards] = useState<ICard[]>(cards)
    const onSearch = (tag: string) => {
        const getCards = async () => {
            try {
                const fetchedCard = await getCardsBy(tag);
                setLawCards(fetchedCard as ICard[])
            } catch (error) {
                console.log("error", error);
            }
        };
        getCards();
    };

    return (
        <div className={styles.root}>
            <div className={styles.searchContainer}>
                <SearchBar
                    title={"Որոնել"}
                onSerach={onSearch}
                />
            </div>
            <div className={styles.newsCardListContainer} >
                {lawCards.map((card: ICard) => (
                    <InfoCard
                        card={card}
                    />
                ))}
            </div>
        </div>
    )
}

export default Home;
