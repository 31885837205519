import axios from "axios";
import {ICard} from "../globalTypes/globalTypes";

const apiUrl = process.env.REACT_APP_API_URL;

export const getCardBy = async (id: string) => {

    try {
        console.log("id", id);
        const response = await axios({
            method: 'get',
            url: `${apiUrl}units/${id}`,
        });
        return (response.data as ICard);
    } catch (error) {
        return ({
            response: null,
            error: error,
        });
    }
}

export const getCardsBy = async (tag: string) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${apiUrl}knodes/_search`,
            params: {
                query: tag,
            },
        });
        return (response.data as ICard[]);
    } catch (error) {
        return ({
            response: null,
            error: error,
        });
    }
}
