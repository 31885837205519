import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: '100%',
        gap: 20,
        padding: '0px 30px',
        borderBottom: '1px solid #C6C5F4'
    },
    imageContainer: {
    },
    navBarContainer: {
        display: "flex",
        minHeight: 56,
    },
    link: {
        fontSize: '14px',
        fontWeight: '400',
        textDecoration: 'none',
        lineHeight: '1.9',
        color: '#242375',
    },
}, {
    name: 'header',
});

export default useStyles;
