import {Route, Routes} from "react-router-dom";
import React from "react";
import {IContent, IPage} from "./types";

const Content = ({
                     pages,
                 }: IContent) => {

    return (
        <Routes>
            {pages.map((page: IPage) => (<Route path={page.path} element={page.element} />))}
        </Routes>
    )
}

export default Content;
