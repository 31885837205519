import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        cursor: 'pointer',
        backgroundColor: "#E2E1E1",
        borderRadius: 10,
    },
}, {
    name: 'news-card',
});

export default useStyles;
