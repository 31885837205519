import useStyles from "./styles";
import React, {useState} from "react";
import {Button, InputBase} from "@mui/material";
import IconSearch from "../../assets/icons/iconSearch.svg";
import {ISearchBar} from "./types";

const SearchBar = ({title, onSerach}: ISearchBar) => {
    const styles = useStyles()
    const [inputValue, setInputValue] = useState<string>("");
    return (
        <div className={styles.root}>
            {<img src={IconSearch} alt="Send Icon" style={{padding: 10, width: 24, height: 24}}/>}
            <InputBase
                sx={{ml: 1, flex: 1,}}
                placeholder="մեկ որոնումով / որոնվող տեքստ ,օրենք ,հոդված,թիվ"
                onChange={event => setInputValue(event.target.value)}
            />
            <Button
                variant={"contained"}
                sx={{
                    width: 106,
                    minHeight: 56,
                    backgroundColor: '#EBEBEB',
                    color: "#706EE3",
                    borderLeft: "1px solid #706EE3",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: '#A5A4DD',
                        color: "#FFFFFF",
                    },
                    "&:active": {
                        backgroundColor: '#706EE3',
                        color: "#FFFFFF",
                    },
                    fontSize: 12,
                    fontWeight: 500,
                }}
                onClick={() => onSerach(inputValue)}
            >
                {title}
            </Button>
        </div>
    );
}

export default SearchBar;
