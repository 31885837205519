import useStyles from "./styles";
import React from "react";
import {Card, CardContent, Typography} from "@mui/material";
import {ICardGroup} from "./types";

const CardGroup = ({
                      context,
                      onClick,
                  }: ICardGroup) => {
    const styles = useStyles()

    return (
        <div>
            <Card
                onClick={onClick}
                className={styles.root}
            >
                <CardContent>
                    <Typography variant="body2" style={{ fontWeight: 400, fontSize: "14px", lineHeight: "19.07px" }}>
                        {context.contextName}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export default CardGroup;
