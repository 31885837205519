import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Layout} from "./components/layout/mainLayout";
import {Header} from "./components/header";
import {BrowserRouter} from "react-router-dom";
import {IPage} from "./components/content/types";
import LegalActs from "./pages/mainPages/legalActs/LegalActs";
import Projects from "./pages/mainPages/projects/Projects";
import News from "./pages/mainPages/news/News";
import ContactUs from "./pages/mainPages/contactUs/ContactUs";
import Content from "./components/content/Content";
import Home from "./pages/mainPages/home/Home";
import {createTheme, ThemeProvider} from "@mui/material";
import Views from "./pages/contentPages/views/Views";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // Disables ripple globally
            },
        },
    },
});

const mainPages: IPage[] = [
    {
        title: "home",
        path: "/home",
        element: <Home />,
    },
    {
        title: "legal-acts",
        path: "/legal-acts",
        element: <LegalActs />,
    },
    {
        title: "projects",
        path: "/projects",
        element: <Projects />,
    },
    {
        title: "news",
        path: "/news",
        element: <News />,
    },
    {
        title: "contact-us",
        path: "/contact-us",
        element: <ContactUs />,
    },
];

const innerPages: IPage[] = [
    {
        title: "views",
        path: "/views",
        element: <Views />,
    },
];

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <Layout
                  header={<Header pageTitles={mainPages.map(item => (item.title))}/>}
                  content={<Content pages={[...mainPages, ...innerPages]}/>}
                  footer={<div>Footer</div>}
              />
          </ThemeProvider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

